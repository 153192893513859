
  import { getWidgetWrapper } from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/993d288074d36378/statics/falcon-ci-repos/bookings-calendar-catalog-viewer/packages/bookings-calendar-widget/src/components/BookingCalendar/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor';

  

  import { initI18n as initI18n } from 'yoshi-flow-editor-runtime/build/esm/i18next/init';



  import stylesParams from '/home/builduser/agent00/work/993d288074d36378/statics/falcon-ci-repos/bookings-calendar-catalog-viewer/packages/bookings-calendar-widget/src/components/BookingCalendar/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://5c05b49c51a145fb801c55a588742af8@sentry.wixpress.com/1120',
      id: '5c05b49c51a145fb801c55a588742af8',
      projectName: 'bookings-calendar-widget',
      teamName: 'bookings',
    };

  var UserComponent = getWidgetWrapper({
      initI18n,
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'BookingCalendar',
    localeDistPath: 'assets/locales',
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  export default {
    component: UserComponent
  };
