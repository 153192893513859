import React from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { Divider } from 'wix-ui-tpa/Divider';
import { useEnvironment } from 'yoshi-flow-editor';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import { HeaderViewModel } from '../../ViewModel/headerViewModel/headerViewModel';
import { st, classes } from './Header.st.css';

export type HeaderProps = {
  viewModel: HeaderViewModel;
};

const Header: React.FC<HeaderProps> = ({ viewModel }) => {
  const { isMobile, isRTL } = useEnvironment();
  const { title, subtitle, isSubtitleVisible } = viewModel;
  const shouldShowSubtitle = isSubtitleVisible && subtitle;

  return (
    <div data-hook="header" className={st(classes.wrapper, { isRTL })}>
      <Text
        data-hook="header-title"
        className={classes.title}
        tagName={AccessibilityHtmlTags.MainHeading}
      >
        {title}
      </Text>
      {shouldShowSubtitle ? (
        <Text
          data-hook="header-subtitle"
          className={st(classes.subtitle, {
            isMobile,
          })}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {subtitle}
        </Text>
      ) : null}
      <Divider data-hook="header-divider" />
    </div>
  );
};

export default Header;
