import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './BookingDetails.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import React, { useContext, useState } from 'react';
import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';
import { Button } from 'wix-ui-tpa/Button';
import { ReactComponent as CameraSVG } from 'wix-ui-tpa/dist/src/assets/icons/Camera.svg';
import { useEnvironment } from 'yoshi-flow-editor';
import { BookingDetailsDataHooks } from './BookingDetailsDataHooks';
import {
  BookingDetailsViewModel,
  SelectableOptions,
} from '../../ViewModel/bookingDetailsViewModel/bookingDetailsViewModel';
import { Divider } from 'wix-ui-tpa/Divider';
import { Dropdown } from 'wix-ui-tpa/Dropdown';
import { CalendarActionsContext } from '../../Actions/actionsContext';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { Dropdowns } from '../../../../utils/bi/consts';
import { mapSelectableOptionsToDropdownOptions } from '../../../../utils/selectableOptions/selectableOptions';

export interface BookingDetailsProps {
  viewModel: BookingDetailsViewModel;
}

const SlotDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const {
    serviceName,
    dateAndTime,
    paymentDescription,
    selectableOptions,
    videoConferenceBadgeText,
  } = viewModel;

  const duration = selectableOptions.find(
    (selectableOption) => selectableOption.key === Dropdowns.DURATION,
  );
  const location = selectableOptions.find(
    (selectableOption) => selectableOption.key === Dropdowns.LOCATION,
  );
  const staffMember = selectableOptions.find(
    (selectableOption) => selectableOption.key === Dropdowns.STAFF_MEMBER,
  );
  return (
    <>
      <div
        data-hook={BookingDetailsDataHooks.SLOT_DETAILS}
        className={st(classes.serviceNameAndDate)}
      >
        {videoConferenceBadgeText && (
          <Badge
            data-hook={BookingDetailsDataHooks.VIDEO_CONFERENCE_BADGE}
            priority={BADGE_PRIORITY.light}
            icon={<CameraSVG />}
            className={st(classes.videoConferenceBadge)}
          >
            {videoConferenceBadgeText}
          </Badge>
        )}
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_NAME}
          className={st(classes.commonTitleStyles)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {serviceName}
        </Text>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DATE_AND_TIME}
          className={st(classes.commonTextStyle)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {dateAndTime}
        </Text>
      </div>
      {location?.options.length === 1 ? (
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_LOCATION}
          className={st(classes.commonTextStyle)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {location.options[0].value}
        </Text>
      ) : null}
      {staffMember?.options.length === 1 ? (
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_STAFF_MEMBER}
          className={st(classes.commonTextStyle)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {staffMember.options[0].value}
        </Text>
      ) : null}
      {duration?.options.length === 1 ? (
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_DURATION}
          className={st(classes.commonTextStyle)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {duration.options[0].value}
        </Text>
      ) : null}
      <Text
        data-hook={BookingDetailsDataHooks.SLOT_PLAN_TYPE}
        className={st(classes.commonTextStyle)}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {paymentDescription}
      </Text>
    </>
  );
};
const SlotPreferences: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { onSlotOptionSelectedAction } = useContext(CalendarActionsContext);
  const randomId = () => {
    return Math.random().toString(36).substring(7);
  };
  const [selectableStaffMembersKey, setSelectableStaffMembersKey] = useState(
    randomId(),
  );
  const [selectableLocationsKey, setSelectableLocationsKey] = useState(
    randomId(),
  );
  const [selectableDurationsKey, setSelectableDurationsKey] = useState(
    randomId(),
  );
  const initialiseDropDowns = () => {
    setSelectableStaffMembersKey(randomId());
    setSelectableLocationsKey(randomId());
    setSelectableDurationsKey(randomId());
    onSlotOptionSelectedAction({
      location: '',
      duration: '',
      staffMember: '',
    });
  };

  const {
    selectableOptions,
    bookingDetailsPreferencesHeaderText,
    bookingDetailsClearText,
  } = viewModel;

  const getSelectableDynamicKey = (dropdown: string) => {
    switch (dropdown) {
      case Dropdowns.STAFF_MEMBER:
        return selectableStaffMembersKey;
      case Dropdowns.LOCATION:
        return selectableLocationsKey;
      case Dropdowns.DURATION:
        return selectableDurationsKey;
    }
  };
  const getSelectableDynamicDataHook = (dropdown: string) => {
    switch (dropdown) {
      case Dropdowns.STAFF_MEMBER:
        return BookingDetailsDataHooks.SLOT_STAFF_MEMBER_DROPDOWN;
      case Dropdowns.LOCATION:
        return BookingDetailsDataHooks.SLOT_LOCATION_DROPDOWN;
      case Dropdowns.DURATION:
        return BookingDetailsDataHooks.SLOT_DURATION_DROPDOWN;
    }
  };

  return (
    <div data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_WRAPPER}>
      <Divider
        className={classes.divider}
        data-hook={BookingDetailsDataHooks.DEVIDER}
      />
      <div className={st(classes.preferencesWrapper)}>
        <Text
          data-hook={BookingDetailsDataHooks.SLOT_PREFERENCES_TITLE}
          className={st(classes.commonTitleStyles)}
          tagName={AccessibilityHtmlTags.Paragraph}
        >
          {bookingDetailsPreferencesHeaderText}
        </Text>
        <TextButton
          data-hook={BookingDetailsDataHooks.SLOT_CLEAR_TITLE}
          className={st(classes.commonTitleStyles)}
          onClick={() => initialiseDropDowns()}
        >
          {bookingDetailsClearText}
        </TextButton>
      </div>

      {selectableOptions.map((selectableOption: SelectableOptions) => {
        const options = mapSelectableOptionsToDropdownOptions(
          selectableOption.options,
          selectableOption.headerText,
        );
        if (selectableOption.options.length > 1) {
          return (
            <Dropdown
              error={!!selectableOption.errorMessage}
              errorMessage={selectableOption.errorMessage}
              data-hook={getSelectableDynamicDataHook(selectableOption.key)}
              className={st(classes.commonDropDownStyle)}
              placeholder={selectableOption.headerText}
              options={options}
              key={getSelectableDynamicKey(selectableOption.key)}
              onChange={(slotDropdownOption) =>
                onSlotOptionSelectedAction({
                  [selectableOption.key]: slotDropdownOption.id,
                })
              }
            />
          );
        }
      })}
    </div>
  );
};
const BookingDetails: React.FC<BookingDetailsProps> = ({ viewModel }) => {
  const { isMobile, isRTL } = useEnvironment();
  const { onSubmitAction } = useContext(CalendarActionsContext);

  const shouldShowPreferences = () => {
    return selectableOptions.some(
      (selectableOption) => selectableOption.options.length > 1,
    );
  };
  const { selectableOptions, bookingDetailsBookNowText } = viewModel;

  return (
    <div
      data-hook={BookingDetailsDataHooks.BOOKING_DETAILS_SELECTION_WRAPPER}
      className={st(classes.root, { isMobile, isRTL })}
    >
      <SlotDetails viewModel={viewModel} />
      {shouldShowPreferences() ? (
        <SlotPreferences viewModel={viewModel} />
      ) : null}
      <Button
        className={st(classes.bookButton, { isMobile })}
        data-hook={BookingDetailsDataHooks.BOOK_BUTTON}
        upgrade
        onClick={() => onSubmitAction()}
      >
        {bookingDetailsBookNowText}
      </Button>
    </div>
  );
};
export default BookingDetails;
